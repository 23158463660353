body {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

img {
    -webkit-user-drag: none;
}

p {
    margin-bottom: 0px;
}


.App_flex_box {
    display: flex;
}

// Navbar
.Topbar {
    position: sticky;
    top: 0px;
    z-index: 1;

    .bg-body-tertiary {
        padding: 30px 5px;
        background-color: #262626 !important;

        .navbar-toggler-icon {
            background-image: url(../public/images/toggle.png);
        }

        .Navbar-logo {
            img {
                width: 160px;
                height: 40px;
            }
        }

        .Nav-link {
            color: #ffffff;
            font-weight: 500;
            box-shadow: none;
            font-size: 15px;
            text-decoration: none;
            padding-left: 30px;
        }

        a:hover {
            color: #757474;
            font-weight: 700;
        }

        .flex-box {
            background-color: #333333;
            border: 2px solid #5c5c5c;
            color: #ffffff;
            border-radius: 2px;
            box-shadow: none;
            display: flex;
            align-items: center;
            margin: 0px 30px;

            .form-control {
                background-color: #333333;
                border: none;
                box-shadow: none;
                content: none;
                color: #999999;
            }

            .form-control::placeholder {
                color: #999999;
            }

            .icon {
                cursor: pointer;
                color: #ffffff;
                right: 0px;
            }

            .icon:hover {
                color: #bebebe34;
            }
        }

        .Login-portion {
            display: flex;
            margin-left: auto;

            .Submit {
                background-color: #d9251d;
                border-radius: 3px;
                font-weight: 600;
                border: none;
                margin-right: 5px;
                padding: 6px 8px;
                font-size: 14px;
            }

            .Submit:hover {
                background-color: rgba(212, 212, 212, 1.0);
            }

            // Dropdown
            .dropdown-toggle {
                background-color: #262626;
                border: none;
                box-shadow: none;
                color: #ffff00;
                padding: 6px 0px 6px 12px;

                .css-i4bv87-MuiSvgIcon-root {
                    width: 27px;
                    height: 27px;
                }
            }

            .dropdown-menu {
                // right: -15px !important;
                left: -310%;
                background-color: #262626;
                box-shadow: 0 0 11px #3f3f3f;

                border-radius: 0px;
                border-top: 3px solid #d9251d;

                .btn {
                    background-color: transparent;
                    border: none;
                    width: 100%;
                    border-radius: 0px;
                    font-size: 14px;
                    box-shadow: none;
                }

                .btn:hover {
                    background-color: #50505080;
                }
            }

            .dropdown-toggle::after {
                display: none;
            }
        }
    }

}

// offcanvas
.offcanvas {
    max-width: 220px;
    background-color: rgba(0, 0, 0, 1.00);

    .offcanvas-header {
        padding: 16px 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        .btn-close {
            padding-right: 40px;
            box-shadow: none;
            background-image: url(../public/images/Closebtn.png);
        }
    }

    .offcanvas-body {
        padding: 0px;

        .Nav-link {
            color: #ffffff;
            font-weight: 500;
            box-shadow: none;
            font-size: 15px;
            text-decoration: none;
            padding: 10px 40px 10px 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            display: flex;
            align-items: center;

            .css-i4bv87-MuiSvgIcon-root {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

            .css-vubbuv {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }

        }

        .Nav-link:first-child {
            padding-top: 0px;
        }

        a:hover {
            color: #757474;
            font-weight: 700;
        }
    }

}

.Offcanvas-btn {
    background-color: transparent;
    border-radius: 5px;
    font-weight: 600;
    border: 2px solid #5c5c5c;
    padding: 4px 8px;
    font-size: 16px;
    box-shadow: none;

    img {
        width: 25px;
        height: 22px;
    }
}

.Offcanvas-btn:hover,
.Offcanvas-btn:focus,
.Offcanvas-btn-two:hover,
.Offcanvas-btn-two:focus {
    background-color: transparent;
}

// Offcanvas-two
.Offcanvas-btn-two {
    background-color: transparent;
    border: none;
    box-shadow: none;
    // padding-right: 0px;
}




.Offcanvas-two {
    background-color: rgba(242, 242, 242, 1.0);
    // .offcanvas-header-two{}
}

// Sidebar-lft
.Sidebar-lft {
    background-color: rgba(242, 242, 242, 1.0);
    height: 100%;
    width: 240px;
    padding: 0px 0px 30px 20px;
    min-height: 100vh;

    h5 {
        font-size: 16px;
        font-weight: 700;
        padding-top: 30px;
    }

    .flex-box-two {
        padding-top: 20px;
        display: flex;
        text-decoration: none;

        .channel-details {
            margin: -5px 0px 0px 8px;
            color: #000000;

            .channel-name {
                font-size: 14px;
                font-weight: 700;
            }

            .videos-number {
                font-size: 12px;
                color: #999999;
            }

            .views {
                font-size: 13px;
                color: #999999;

                .css-i4bv87-MuiSvgIcon-root {
                    width: 16px;
                    height: 16px;
                }

                .css-vubbuv {
                    width: 16px;
                    height: 16px;
                }
            }
        }

    }


}

// Category
.Category {
    h5 {
        padding-bottom: 20px;
    }

    .flex-box-four {
        line-height: 30px;
        display: flex;
        color: rgba(102, 102, 102, 1.0);
        font-weight: 650;
        font-size: 14px;
        text-decoration: none;

        .channel-type {
            .Type {
                padding-left: 5px;
            }
        }
    }
}

// Footer
.Footer {
    background-color: #262626;
    padding: 50px 20px;
    color: #ffffff;

    .img-logo {
        width: 234px;
        height: 60px;
    }

    a {
        color: #ffffff;
        text-decoration: none;
        line-height: 35px;
    }

    a:hover {
        color: #d9251d;
        font-weight: 600;
    }

    .Footer_inner {
        padding-top: 30px;
    }

    .flex-box-three {
        display: flex;
        padding-top: 30px;

        .css-i4bv87-MuiSvgIcon-root {
            padding-right: 10px;
            font-size: 40px;
        }

        .css-vubbuv {
            padding-right: 10px;
            font-size: 40px;
        }
    }
}

// Tabs

.Tabs {
    padding: 30px 10px 0px 20px;

    .flex-box-five {
        display: flex;
        padding-bottom: 20px;
        text-decoration: none;
        color: #202020;
        width: fit-content;

        .profile-img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }

        .channel-details {
            padding-left: 10px;
            height: 100%;

            .name {
                font-size: 16px;
                font-weight: 600;
            }

            .subscribers {
                padding-top: 20px;
                font-size: 12px;
            }
        }
    }

    /* width */
    .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
        display: none;
    }

    .css-heg063-MuiTabs-flexContainer {
        overflow-x: auto;
    }

    .css-13xfq8m-MuiTabPanel-root {
        padding: 24px 10px;
    }

    .css-19kzrtu {
        padding: 24px 10px;
    }


}

.Cards-box {
    .Latest-tab {
        .card {
            border: none;
            margin-bottom: 20px;

            .Card_img {
                position: relative;
                // border-radius: 5px;

                img {
                    height: 100%;
                    width: 100%;
                    min-height: 200px;
                    border-radius: 5px;
                }

                .play_button {
                    background-color: rgba(34, 34, 34, 0.911);
                    border-radius: 5px;
                    color: #ffffff;
                    border: none;
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    display: none;
                    padding: 10px;
                    font-size: 14px;
                    font-weight: 600px;
                    width: 100%;
                    height: 100%;
                    //   text-decoration: none;
                }


            }

            .Card_img:hover .play_button {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .card-body {
                padding-inline: 10px;

                .card-title {
                    font-size: 16px;
                    font-weight: 700;
                }

                .card-text {
                    font-size: 13px;
                    color: #666666;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .Video-details {
                    padding-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .views {
                        font-size: 13px;
                        color: #585858;
                        text-decoration: none;
                        font-weight: 500;
                        padding-right: 4px;

                        .css-i4bv87-MuiSvgIcon-root {
                            width: 16px;
                            height: 16px;
                        }

                        .css-vubbuv {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    .views:hover {
                        color: #202020;
                    }
                }
            }

        }

        .card:hover {
            cursor: pointer;
            box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.10);
        }

    }
}

// PLAY 
.Play {
    padding: 20px;

    #videosection {
        img {
            border-radius: 5px;
            width: 100%;
        }

        .content {
            margin: 0px;
            font-weight: 600;
            font-size: 20px;
            padding: 10px 0px;
        }

        .channel_details {
            padding-top: 20px;
            display: flex;
            align-items: center;

            .logo_img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            .name {
                padding-left: 5px;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    #VideosCollection {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 10px;

        .Upper_portion {
            .flex_box {
                display: flex;
                justify-content: space-between;

                .title {
                    margin: 0px;
                    font-weight: 600;
                    font-size: 20px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .channelName {
                    margin: 0px;
                    font-weight: 500;
                    font-size: 12px;
                    color: rgb(94, 93, 93);
                }

                .Dropdown_box {
                    .dropdown-toggle {
                        background-color: transparent;
                        color: #000000;
                        border: none;
                    }

                    .dropdown-menu.show {
                        background-color: #262626;
                        box-shadow: 1px 1px 6px 2px #787878;

                        border-radius: 0px;
                        border-top: 3px solid #d9251d;
                    }

                    .dropdown-item {
                        background-color: transparent;
                        color: #ffffff;
                        font-size: 14px;
                    }

                    .dropdown-item:hover {
                        background-color: #50505080;
                    }

                    .dropdown-toggle::after {
                        display: none;
                    }
                }

            }
        }

        .collection {
            padding-top: 20px;
            height: 60vh;
            overflow-y: auto;
            overflow-x: hidden;

            .flex_box_2 {
                display: flex;
                margin-bottom: 10px;

                img {
                    width: 60px;
                    height: 60px;
                    // border-radius: 5px;

                }

                .flex_box_3 {
                    display: flex;
                    flex-direction: column;
                    padding-left: 10px;
                    height: 100%;
                }

                .title {
                    margin: 0px;
                    font-size: 14px;
                    font-weight: 600;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .channelName {
                    font-weight: 500;
                    font-size: 12px;
                    color: rgb(94, 93, 93);
                    padding-top: 15px;
                }
            }
        }
    }
}


// Login_Page
.Login_Page {
    .card {
        border: none;
        box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);
        margin: 40px auto;
        padding: 50px 20px;
        width: 400px;
        // min-height: 50vh;

        .sm-text {
            font-size: 15px;
            color: #2F2B3DC7;
        }

        form {
            .form-label {
                padding: 20px 0px 5px 0px;
                margin-bottom: 0px;
            }

            .form-control {
                background-color: #333333;
                border: none;
                box-shadow: none;
                color: #999999;
                border-radius: 3px;
            }

            .form-control::placeholder {
                color: #999999;
            }
        }

        a {
            text-decoration: none;
            color: #525CEB;
            font-weight: 600;
            font-size: 14px;
        }


        .Submit {
            background-color: #d9251d;
            border-radius: 3px;
            font-weight: 600;
            border: none;
            padding: 10px 8px;
            font-size: 14px;
            margin: 20px 0px;
        }

        .Submit:hover {
            background-color: #e7382e;
        }

        .lines {
            display: flex;
            align-items: center;
            margin: 5px;
            justify-content: space-between;


            .text {
                padding: 0px 2px;
                border: 1px solid #33333379;

                h6 {
                    margin-bottom: 0px;
                    color: rgb(0, 0, 0);
                    font-size: 10px;
                }
            }

            .hr {
                color: #333333;
                width: 100%;
            }
        }

        .Googles-btn {
            display: flex;
            justify-content: center;
            padding-top: 20px;

            .btn {
                background-color: transparent;
                color: #d9251d;
                border: 1px solid #d9251d;
                border-radius: 2px;
                box-shadow: none;
            }

            .btn:hover {
                background-color: #d9251d;
                color: #ffffff;
            }
        }

        .New-account {
            display: flex;
            justify-content: center;
            padding-top: 20px;
        }
    }
}

// Channel-detail
.Channel-details {
    padding: 30px 20px;

    .flex-box-five {
        display: flex;
        padding-bottom: 20px;

        .profile-img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }

        .channel-details {
            padding-left: 10px;
            // margin-top:-5px ;
            height: 100%;

            .name {
                font-size: 16px;
                font-weight: 600;
            }

            .subscribers {
                padding-top: 20px;
                font-size: 12px;
                color: #202020;
            }
        }
    }
}