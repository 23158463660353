@media screen and (max-width: 991px) {
    // Sidebar-lft
    .Sidebar-lft {
        width: auto;
    }

    // responsiveTopbar
    .Topbar {

        .bg-body-tertiary {
            padding: 10px;

            .flex-box {
                width: 90%;
                margin-inline: auto 0px;
            }
        }
    }

    // Play
    .Play {

        #VideosCollection {
            margin-top: 20px;
        }
    }

    // Footer
    .Footer {

        .img-logo {
            display: flex;
            justify-content: center;
            margin: auto;
        }

        h4 {
            padding-top: 40px;
        }

        .Footer_inner {
            padding-top: 10px;
            text-align: center;
            font-size: 14px;

            p {
                max-width: 500px;
                margin: auto;
            }
        }

        .flex-box-three {
            display: flex;
            padding-top: 10px;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 425px) {

    // Footer
    .Footer {
        .img-logo {
            width: 200px;
            height: 50px;
        }
    }

    // responsiveTopbar
    .Topbar {

        .bg-body-tertiary {
            padding: 10px;

            .Navbar-logo {
                img {
                    width: 130px;
                    height: 35px;
                }
            }

            .flex-box {
                width: 80%;

                .form-control {
                    padding: 3px 12px;
                }
            }

            .Login-portion {
                display: flex;
                margin-left: auto;

                .dropdown-toggle {

                    .css-i4bv87-MuiSvgIcon-root {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    // Offcanvas-btn
    .Offcanvas-btn {
        background-color: transparent;
        border-radius: 5px;
        font-weight: 600;
        border: 2px solid #5c5c5c;
        padding: 2px 6px 4px 6px;
        font-size: 16px;
        box-shadow: none;

        img {
            width: 22px;
            height: 20px;
        }
    }

    // Login_Page
    .Login_Page {
        .card {
            width: 90%;
            margin: 40px auto;
        }
    }
}